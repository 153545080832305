import { template as template_54ece03de32643378b06755c866702fa } from "@ember/template-compiler";
const FKLabel = template_54ece03de32643378b06755c866702fa(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
